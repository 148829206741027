import React, { useEffect, useRef, useState } from 'react';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import { graphql, navigate } from 'gatsby';
import * as styles from './lavo.module.css';
import PageLayout from '../components/atoms/Contentful/ContentfulPageLayout';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider,
} from 'react-scroll-parallax';
// import PostListContentful from "../components/organisms/PostListContentful/PostListContentful";
import Icon from '../components/atoms/Icon/Icon';
import Slick from 'react-slick';
import BlogPostCardContentful from '../components/molecules/BlogPostCardContentful/BlogPostCardContentful';
//logos&icons- b&W
import lavoDigitalImage from '../../static/LAVO-Digital.svg';

import lavoHydrogenImage from '../../static/LAVO Hydrogen Black Logo.svg';

import lavoLifeImage from '../../static/LAVO-Life.svg';

//logos&icons- color
import lavoDigitalColorImage from '../../static/lavo-digital-color.svg';

import lavoHydrogenColorImage from '../../static/LAVO Hydrogen Blue Logo.svg';

import lavoLifeColorImage from '../../static/lavo-life-color.svg';

import styled from 'styled-components';
// import { useWindowDimensions } from "../helpers/general";

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
  margin-top: 10px;
  max-width: 100%;

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 15px;
    height: auto;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  cursor: pointer;

  img {
    max-width: 100%;
    height: auto;
  }
  @media (max-width: 768px) {
    img {
      max-width: 100%;
      height: 35px !important;
    }
  }
`;

const LavoHome = ({ data }) => {
  // console.log('data from home page', data)
  const { pageName, pageTitle, metaTitle, metaPreviewImage } = data.homePage;
  const featuredPosts = data.blogList.nodes;
  const page = data.homePage;
  const contentfulPage = (page.blocks && page.blocks[0]) || false;
  const contentfulSections = (contentfulPage && contentfulPage.blocks) || false;
  const layoutStyle = (page.logoStyle && page.logoStyle.logoStyle) || false;
  const genClass = pageName
    ? 'contentful-page page-' +
      pageName
        .trim()
        .replace('.', '')
        .replace(/[^A-Z0-9]+/gi, '-')
        .toLowerCase()
    : '';
  const dataHelmet = { pageName, pageTitle, metaTitle };
  const blockAlignment =
    (contentfulPage && contentfulPage.alignment.alignment) || false;
  const blockWidth =
    (contentfulPage.blockWidth && contentfulPage.blockWidth.blockWidth) ||
    false;
  const textColour = contentfulPage.textColour || false;
  const buttonRef = useRef();
  const buttonContainerRef = useRef(null);
  const [isIframeShown, setIframeShown] = useState(false);
  const seoTitle = page.seoTitle || page.pageName;
  const [hoveredImage, setHoveredImage] = useState(null);
  // const isSSR = typeof window === 'undefined';
  // const [isMobileLayout, setIsMobileLayout] = useState(
  //   isSSR ? false: window.innerWidth <= 767
  // );

  // const handleResize = () => {
  //   setIsMobileLayout(window.innerWidth <= 767);
  // };

  // useEffect(() => {
  //   // Initialize isMobileLayout state
  //   if(!isSSR){

  //     setIsMobileLayout(window.innerWidth <= 767);

  //     // Add event listener for window resize
  //     window.addEventListener('resize', handleResize);

  //     // Clean up the event listener on component unmount
  //     return () => {
  //       window.removeEventListener('resize', handleResize);
  //     };
  //   }
  // }, [isSSR]);

  const handleImageHover = imageKey => {
    setHoveredImage(imageKey);
  };

  const handleImageLeave = () => {
    setHoveredImage(null);
  };

  const images = [
    {
      image: hoveredImage === 'lavoLife' ? lavoLifeColorImage : lavoLifeImage,
      key: 'lavoLife',
      link: '/lavo-life/',
    },
    {
      image:
        hoveredImage === 'lavoHydrogen'
          ? lavoHydrogenColorImage
          : lavoHydrogenImage,

      key: 'lavoHydrogen',
      link: '/lavo-hydrogen/',
    },
    {
      image:
        hoveredImage === 'lavoDigital'
          ? lavoDigitalColorImage
          : lavoDigitalImage,
      key: 'lavoDigital',
      link: '/lavo-digital/',
    },
  ];

  // const { width } = useWindowDimensions();
  // const isMobile = width < 768;

  const handlePlayClick = () => {
    setIframeShown(true);
  };

  const handleVideoClose = () => {
    setIframeShown(false);
  };

  const handleMouseMove = event => {
    const containerRect = buttonContainerRef.current.getBoundingClientRect();
    const offsetX =
      containerRect.width - 75 >= event.offsetX
        ? event.offsetX > 0
          ? event.offsetX
          : 50
        : containerRect.width - 50;
    const offsetY =
      containerRect.height - 75 >= event.offsetY
        ? event.offsetY > 0
          ? event.offsetY
          : 50
        : containerRect.height - 50;
    buttonRef.current.style.top = offsetY + 'px';
    buttonRef.current.style.left = offsetX + 'px';
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();

    const containerElement = buttonContainerRef.current;

    containerElement?.addEventListener('mouseenter', e => {
      containerElement?.addEventListener('mousemove', handleMouseMove);
    });
  }, []);

  return (
    <ParallaxProvider>
      <Layout
        headerstyle={'dark'}
        varHelmet={dataHelmet}
        genClass={genClass}
        layoutStyle={layoutStyle}>
        <Seo
          title={seoTitle}
          description={page.metaDescription}
          image={
            metaPreviewImage.file.url
              ? `https:${metaPreviewImage.file.url}`
              : ''
          }
        />
        <div className={styles.container}>
          <div className={styles.heroImage}>
            <ParallaxBanner
              layers={[
                {
                  image: contentfulSections[0].desktopImage?.file.url,
                  speed: -30,
                },
              ]}
              className={`${styles.parallaxContainer} ${styles.parallaxDesktop} img desktop`}
            />
            <ParallaxBanner
              layers={[
                {
                  image: contentfulSections[0].mobileImage?.file.url,
                  speed: -5,
                },
              ]}
              className={`${styles.parallaxContainer} ${styles.parallaxMobile} img mobile`}
            />
          </div>
          <div className={styles.homeWereLavo}>
            <Container size={'medium'}>
              <div
                data-components
                data-index={1}
                data-alignment={blockAlignment}
                data-text-color={textColour}
                style={{ width: blockWidth }}
                data-last={false}
                data-aos='fade-up'
                data-aos-easing='linear'
                data-aos-duration='1500'>
                <PageLayout
                  pageName={pageName}
                  section={contentfulSections[1]}
                  sectionIndex={1}
                />

                <ImgContainer>
                  {images.map(item => (
                    <ImageWrapper
                      key={item.key}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onMouseEnter={() => handleImageHover(item.key)}
                      onMouseLeave={handleImageLeave}
                      onClick={() => navigate(item.link)}>
                      <img
                        src={item.image}
                        alt={`Lavo ${item.key}`}
                        style={{
                          marginRight: '15px',
                          width: '345px',
                          height: '45px',
                        }}
                      />
                    </ImageWrapper>
                  ))}
                </ImgContainer>
              </div>
            </Container>
          </div>
          <div
            className={styles.heroImageWithVideo}
            onClick={handlePlayClick}
            role='presentation'>
            <div ref={buttonContainerRef}>
               <ParallaxBanner
                layers={[
                  {
                    image: contentfulSections[6]?.backgroundImage?.desktopImage.file.url,
                    speed: -50,
                  },
                ]}
                className={`${styles.parallaxContainer} img desktop`}
              />
            </div>
            <ParallaxBanner
              layers={[
                {
                  image: contentfulSections[6]?.backgroundImage?.desktopImage.file.url,
                  speed: -5,
                },
              ]}
              className={`${styles.parallaxContainer} img mobile`}
            />
            <div data-btn ref={buttonRef} className={styles.playButton}>
              <div
                className={`btn btn-play`}
                data-addclass={`video`}
                onClick={handlePlayClick}
                aria-hidden='true'>
                Play
              </div>
            </div>
          </div>
          <div className={styles.homeDoubCardWithBackground}>
            <Container size={'medium'}>
              <div
                data-components
                data-index={2}
                data-alignment={blockAlignment}
                data-text-color={textColour}
                style={{ width: blockWidth }}
                data-last={false}
                data-aos='fade-up'
                data-aos-easing='linear'
                data-aos-duration='1500'>
                <PageLayout
                  pageName={pageName}
                  section={contentfulSections[2]}
                  sectionIndex={2}
                />
              </div>
            </Container>
          </div>
          <div
            className={styles.whyLavoFullWidthParallaxWrapper}
            style={{
              backgroundColor: '#' + contentfulSections[3].backgroundColour,
            }}>
            <div className={styles.whyLavoHeroParallaxImage}>
              <img
                src={
                  contentfulSections[3].backgroundImage.desktopImage.file.url
                }
                alt='Lavo Background'
              />
            </div>
            <Parallax speed={20}>
              <div
                data-components
                data-index={3}
                data-alignment={blockAlignment}
                data-text-color={textColour}
                style={{ width: blockWidth }}
                data-last={false}
                className={styles.whyLavoContainerDoubleWithArtwork}
                data-aos='fade-up'
                data-aos-easing='linear'
                data-aos-duration='1500'>
                <PageLayout
                  pageName={pageName}
                  section={contentfulSections[3]}
                  sectionIndex={3}
                />
              </div>
            </Parallax>
          </div>
          {/* New Section */}
          <div
            className={styles.homePageFullWidthParallaxWrapper}
            style={{
              backgroundColor: '#' + contentfulSections[4].backgroundColour,
            }}>
            <div className={styles.whyLavoHeroParallaxImage}>
              <img
                src={
                  contentfulSections[4].backgroundImage?.desktopImage?.file.url
                }
                alt='Lavo Background'
              />
            </div>
            <Container size={'medium'}>
              <Parallax speed={20}>
                <div
                  data-components
                  data-index={4}
                  data-alignment={blockAlignment}
                  data-text-color={textColour}
                  style={{ width: blockWidth }}
                  data-last={false}
                  data-aos='fade-up'
                  data-aos-easing='linear'
                  data-aos-duration='1500'>
                  <PageLayout
                    pageName={pageName}
                    section={contentfulSections[4]}
                    sectionIndex={4}
                  />
                </div>
              </Parallax>
            </Container>
          </div>

          {/* End new section */}
          {featuredPosts && featuredPosts.length > 0 && (
            <div
              className={`${styles.relatedPostsContainer} ${styles.homeNewsContainer}`}>
              <div className={styles.relatedPostsWrapper}>
                <div className={styles.relatedPostsText}>
                  <div className={styles.relatedPostsTitle}>News & Views</div>
                  <a
                    className={`btn btn-cta btn-4 ${styles.seeMore}`}
                    href={'/newsroom'}>
                    <span className='txt'>See more</span>
                    <Icon symbol='iconArrow' />
                  </a>
                </div>
                <Slick
                  className={styles.slider}
                  dots={false}
                  customArrows={false}
                  autoplay={false}
                  slidesToShow={3}
                  responsive={[
                    {
                      breakpoint: 800,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                      },
                    },
                  ]}>
                  {featuredPosts &&
                    featuredPosts.map((post, pIndex) => (
                      <div key={pIndex} className={styles.newsContainer}>
                        <BlogPostCardContentful
                          viewMode={'grid'}
                          cardSize={'large'}
                          titleOnly={false}
                          key={post.id}
                          title={post.blogTitle}
                          excerpt={post.excerpt}
                          slug={post.slug.replace(/^\/|\/$/g, '')}
                          content={post.blogContent.raw}
                          date={post.publishDate}
                          thumbnail={post.blogFeaturedImage?.file?.url || ''}
                        />
                      </div>
                    ))}
                </Slick>
              </div>
            </div>
          )}
        </div>
      </Layout>
      <div
        className={`${styles.iframeContainer} ${
          isIframeShown ? 'iframe-active' : ''
        }`}>
        <div className={styles.innerIframeContainer}>
          <iframe
            src={contentfulSections[6].buttonUrl}
            title='Homepage Video'
            width='1280'
            height='720'
            allow='autoplay'></iframe>
        </div>
        <div
          className={styles.btnClose}
          onClick={handleVideoClose}
          aria-hidden='true'></div>
      </div>
    </ParallaxProvider>
  );
};

export default LavoHome;
export const query = graphql`
  query ContentfulPageLavo {
    homePage: contentfulPage(pageName: { eq: "LAVO" }) {
      pageName
      seoTitle
      pageTitle
      metaCanonicalLink
      metaDescription
      metaTitle
      breadcrumbs
      metaPreviewImage {
        file {
          url
        }
      }
      logoStyle {
        logoStyle
      }
      blocks {
        textColour
        backgroundColour
        alignment {
          alignment
        }
        blockWidth {
          blockWidth
        }
        paddingTopBottom {
          paddingTopBottom {
            paddingTopBottom
          }
        }
        blocks {
          ... on ContentfulBlockImage {
            id
            desktopImage {
              file {
                fileName
                url
              }
            }
            mobileImage {
              file {
                fileName
                url
              }
            }
          }
          ... on ContentfulBlockButtons {
            name
            internal {
              type
            }
            buttons {
              name
              buttonText
              deepLink
              url
            }
            paddingTopBottom {
              paddingTopBottom {
                paddingTopBottom
              }
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
          }
          ... on ContentfulBlockSlides {
            name
            classes
            internal {
              type
            }
            blocks {
              name
              blockWidth {
                blockWidth
              }
              alignment {
                alignment
              }
            }
            backgroundColour
            slide {
              name
              heading
              subText {
                childMarkdownRemark {
                  html
                }
              }
              image {
                altText
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                imageMaxWidth
              }
              vimeoId
              backgroundColor
              button {
                buttonText
                deepLink
                name
                url
              }
              coloredSubText {
                textColor
                subText
              }
            }
            buttonName
            buttonUrl
            heading
            slideStyle {
              slideStyle
            }
            slideToShow {
              slideToShow
            }
            textColour {
              textColour {
                textColour
              }
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            textAlignment {
              textAlignment
            }
          }
          ... on ContentfulBlockSingleCard {
            name
            internal {
              type
            }
            paddingTopBottom {
              paddingTopBottom {
                paddingTopBottom
              }
            }
            backgroundColour
            vimeoId
            content {
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              heading
              headingSize {
                headingSize
              }
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              textAlignment {
                textAlignment
              }
              textColour
              content {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          ... on ContentfulBlockDoubleCards {
            name
            internal {
              type
            }
            title
            backgroundColour
            textColour {
              textColour {
                textColour
              }
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            blocks {
              alignment {
                alignment
              }
              blockWidth {
                blockWidth
              }
              backgroundColour
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            paddingTopBottom {
              paddingTopBottom {
                paddingTopBottom
              }
            }
            blockAlignment {
              blockAlignment
            }
            mobileRevert
            contentLeft {
              heading
              childContentfulBlockContentContentTextNode {
                childMarkdownRemark {
                  html
                }
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              headingSize {
                headingSize
              }
              textAlignment {
                textAlignment
              }
              textColour
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundColour
            }
            contentRight {
              heading
              childContentfulBlockContentContentTextNode {
                childMarkdownRemark {
                  html
                }
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              headingSize {
                headingSize
              }
              textAlignment {
                textAlignment
              }
              textColour
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundColour
            }
          }
          ... on ContentfulBlockContent {
            name
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            heading
            headingSize {
              headingSize
            }
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            textAlignment {
              textAlignment
            }
            imagePosition {
              imagePosition
            }
            internal {
              type
            }
            content {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulBlockAniParallaxScrolling {
            name
            heading
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            internal {
              type
            }
            backgroundColour
            backgroundImage {
              imageMaxWidth
              altText
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            textColour {
              textColour {
                textColour
              }
            }
            style {
              style
            }
            stickyContent {
              headingSize {
                headingSize
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              imagePosition {
                imagePosition
              }
              name
              textAlignment {
                textAlignment
              }
              textColour
              heading
              className
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              content {
                childMarkdownRemark {
                  html
                }
              }
              backgroundColour
              backgroundImage {
                imageMaxWidth
                altText
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              blockAlignment {
                blockAlignment
              }
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
            }
            scrollingContents {
              headingSize {
                headingSize
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              imagePosition {
                imagePosition
              }
              name
              textAlignment {
                textAlignment
              }
              textColour
              heading
              headingColor
              className
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              content {
                childMarkdownRemark {
                  html
                }
              }
              backgroundColour
              backgroundImage {
                imageMaxWidth
                altText
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              blockAlignment {
                blockAlignment
              }
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
            }
          }
          ... on ContentfulBlockTiles {
            name
            internal {
              type
            }
            blockAlignment {
              blockAlignment
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockStyle {
              blockStyle
            }
            backgroundColour
            textColour {
              textColour {
                textColour
              }
            }
            title
            tile {
              ... on ContentfulBlockContent {
                name
                blockWidth {
                  blockWidth {
                    blockWidth
                  }
                }
                buttonName
                buttonUrl
                buttonStyle {
                  buttonStyle
                }
                heading
                headingSize {
                  headingSize
                }
                imagePosition {
                  imagePosition
                }
                imageHoverAnimation {
                  imageHoverAnimation
                }
                backgroundImage {
                  altText
                  imageMaxWidth
                  desktopImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                  mobileImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                }
                textAlignment {
                  textAlignment
                }
                content {
                  childMarkdownRemark {
                    html
                  }
                }
              }
              ... on ContentfulBlockDoubleCards {
                name
                internal {
                  type
                }
                backgroundColour
                textColour {
                  textColour {
                    textColour
                  }
                }
                blocks {
                  alignment {
                    alignment
                  }
                  blockWidth {
                    blockWidth
                  }
                }
                blockWidth {
                  blockWidth {
                    blockWidth
                  }
                }
                blockAlignment {
                  blockAlignment
                }
                mobileRevert
                contentLeft {
                  heading
                  childContentfulBlockContentContentTextNode {
                    childMarkdownRemark {
                      html
                    }
                  }
                  buttonName
                  buttonUrl
                  buttonStyle {
                    buttonStyle
                  }
                  name
                  blockWidth {
                    blockWidth {
                      blockWidth
                    }
                  }
                  backgroundImage {
                    altText
                    imageMaxWidth
                    desktopImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                    mobileImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                  }
                  headingSize {
                    headingSize
                  }
                  textAlignment {
                    textAlignment
                  }
                  textColour
                  imagePosition {
                    imagePosition
                  }
                  imageHoverAnimation {
                    imageHoverAnimation
                  }
                }
                contentRight {
                  heading
                  childContentfulBlockContentContentTextNode {
                    childMarkdownRemark {
                      html
                    }
                  }
                  buttonName
                  buttonUrl
                  buttonStyle {
                    buttonStyle
                  }
                  name
                  blockWidth {
                    blockWidth {
                      blockWidth
                    }
                  }
                  backgroundImage {
                    altText
                    imageMaxWidth
                    desktopImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                    mobileImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                  }
                  headingSize {
                    headingSize
                  }
                  textAlignment {
                    textAlignment
                  }
                  textColour
                  imagePosition {
                    imagePosition
                  }
                  imageHoverAnimation {
                    imageHoverAnimation
                  }
                }
              }
            }
          }
        }
      }
    }
    blogList: allContentfulBlogPost(
      filter: { showInHomepage: { eq: true } }
      limit: 3
    ) {
      nodes {
        blogTitle
        publishDate
        blogContent {
          raw
        }
        slug
        blogFeaturedImage {
          file {
            url
          }
        }
      }
    }
  }
`;

